/*/////////////////////
/-----> /FONT <-----/ 
/////////////////////*/
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;300;400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
/*/////////////////////
/-----> /DEFAULT/ <-----/ 
/////////////////////*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 75px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: #121212 !important;
  color: #fff !important;
  font-family: "Centra", sans-serif !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p,
a,
li,
button,
ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 100%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

@media (min-width: 1700px) {
  main .container {
    max-width: 100%;
    padding: 0 150px;
  }
}

p.success {
  color: green;
}

p.danger {
  color: red;
}
/*/////////////////////
/-----> /NAVBAR/ <-----/ 
/////////////////////*/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition: 0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #121212;
}
nav.navbar a.navbar-brand {
  width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #fff !important;
  letter-spacing: 0.8px;
  font-size: 18px;
  opacity: 0.75;
}

nav.navbar .navbar-nav .nav-link.navbar-link:not(:last-child) {
  padding-right: 15px;
}

nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
  opacity: 1;
}

span.navbar-text {
  display: flex;
  align-items: center;
}

.social-icon {
  display: inline-block;
  margin-left: 15px;
}

.social-icon a {
  width: 42px;
  height: 42px;
  background: rgba(217, 217, 217, 0.1);
  display: inline-flex;
  border-radius: 50%;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
  line-height: 1;
  border: 1px solid rgba(255, 255, 255, 0.5);
}
.social-icon a::before {
  content: "";
  width: 42px;
  height: 42px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 50%;
  transform: scale(0);
  transition: 0.3s ease-in-out;
}
.social-icon a:hover::before {
  transform: scale(1);
}
.social-icon a img {
  width: 40%;
  z-index: 1;
  transition: 0.3s ease-in-out;
}
.social-icon a:hover img {
  filter: brightness(0) saturate(100%) invert(0%) sepia(7%) saturate(98%)
    hue-rotate(346deg) brightness(95%) contrast(86%);
}
.navbar-text button,
.project-content-wrapper button {
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px;
  font-size: 18px;
  margin-left: 15px;
  position: relative;
  background-color: transparent;
  transition: 0.3s ease-in-out;
}
.navbar-text button span,
.project-content-wrapper button span {
  z-index: 1;
}
.navbar-text button::before,
.project-content-wrapper button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover,
.project-content-wrapper button:hover {
  color: #121212;
}
.navbar-text button:hover::before,
.project-content-wrapper button:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 34px;
  height: 18px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
  border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
  width: 34px;
  position: absolute;
  height: 2px;
  background-color: #fff;
  top: 0;
  left: 0;
  content: "";
  z-index: 2;
  transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
  top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: translateY(8px) rotate(-45deg);
  background-color: #fff;
  height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  border-color: transparent;
}

/*/////////////////////
/-----> /BANNER/ <-----/ 
/////////////////////*/
.banner {
  margin-top: 0;
  padding: 260px 0 100px 0;
  background-image: url("./assets/img/banner-bg.png");
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
  filter: brightness(80%);
}
.banner .tagline {
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  background: linear-gradient(
    200deg,
    rgba(0, 119, 204, 0.4),
    rgba(153, 51, 153, 0.4)
  );
  border: 1px solid #993399;
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}

.banner h1 {
  font-size: 3.5rem;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 16px;
  display: block;
}
.banner p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}
.banner button {
  color: #fff;
  font-weight: 700;
  font-size: 1.3rem;
  margin-top: 60px;
  letter-spacing: 0.8px;
  display: flex;
  align-items: center;
}
.banner button svg {
  font-size: 25px;
  margin-left: 10px;
  transition: 0.3s ease-in-out;
  line-height: 1;
}
.banner button:hover svg {
  margin-left: 25px;
}
.banner img {
  animation: updown 3s linear infinite;
}

.link-to-cv {
  text-decoration: none;
  color: #fff;
}
@keyframes updown {
  0% {
    transform: translateY(-20px);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(-20px);
  }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}
/*/////////////////////
/-----> /SKILLS/ <-----/ 
/////////////////////*/
.skill {
  padding: 0 0 calc(3rem + 24px);
  position: relative;
}
.skill-bx {
  background: #151515;
  border-radius: 64px;
  text-align: center;
  padding: 40px 30px;
  margin-top: -60px;
}
.skill h2 {
  font-size: 3rem;
  font-weight: 700;
}
.skill p {
  color: #b8b8b8;
  font-size: 18px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 100px;
  height: 90px;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 28%;
  position: absolute;
  bottom: 0;
  width: 40%;
  z-index: -4;
}

/*/////////////////////
/-----> /PROJECTS/ <-----/ 
/////////////////////*/
.project {
  padding: 3rem 0;
  background-color: black;
}

.Project p {
  margin-bottom: 48px;
}

.project h2 {
  font-size: 3rem;
  font-weight: 700;
  text-align: left;
  padding-top: 24px;
  margin-bottom: 16px;
}

.project p {
  color: #b8b8b8;
  font-size: 18px;
  text-align: left;
}

.project-content-wrapper {
  border: 1px solid #993399;
  background: linear-gradient(
    200deg,
    rgba(0, 119, 204, 0.3),
    rgba(153, 51, 153, 0.3)
  );
  transition: transform 0.2s ease-in-out;
  background-size: cover;
  background-repeat: no-repeat;
}

.project-content-wrapper:hover {
  transform: scale(0.93);
}

.project-card {
  padding-bottom: 24px;
}

.project-content-wrapper {
  width: 100%;
}

.project-content-text {
  margin: 2rem 15px;
}

.project-content-wrapper h4 {
  text-align: left;
  color: #fff;
}

.project-content-wrapper img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.pagination .page-item {
  margin: 0 5px;
}

.pagination .page-link {
  color: #993399;
  background: transparent;
  border: none;
  transition: background 0.2s ease-in-out; /* Add transition for hover effect */
  font-size: 1.2rem; /* Increase font size */
  padding: 0.5rem 1rem; /* Add padding for better touch targets */
}

.pagination .page-link:hover {
  background: rgba(0, 119, 204, 0.5); /* Hover background color */
  color: white; /* Change text color on hover */
}

.pagination .active .page-link {
  background: rgba(153, 51, 153, 0.6);
  color: white;
}

.project-content-wrapper button {
  margin-left: 0;
}

.button-wrapper .left {
  margin-right: 0.5rem;
}

.button-wrapper {
  display: flex;
  justify-content: space-between;
}
/*/////////////////////
/-----> /CONTACT/ <-----/ 
/////////////////////*/
.contact {
  position: relative;
  padding: 72px 0;
}
.contact::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    200deg,
    rgba(0, 119, 204, 0.6),
    rgba(153, 51, 153, 0.6)
  );

  z-index: -1;
}

.contact h2 {
  font-size: 3rem;
  font-weight: 700;
  margin-bottom: 30px;
}
.contact form input,
.contact form textarea {
  width: 100%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  color: #fff;
  margin: 0 0 8px 0;
  padding: 18px 26px;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.8px;
  transition: 0.3s ease-in-out;
}
.contact form input:focus,
.contact form textarea:focus {
  background: rgba(255, 255, 255, 1);
  color: #121212;
}
.contact form input::placeholder,
.contact form textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
.contact form input:focus::placeholder,
.contact form textarea:focus::placeholder {
  color: #121212;
  opacity: 0.8;
}
.contact form button {
  font-weight: 700;
  color: #000;
  background-color: #fff;
  padding: 14px 48px;
  font-size: 18px;
  margin-top: 25px;
  border-radius: 0;
  position: relative;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.contact form button span {
  z-index: 1;
  position: relative;
}
.contact form button:hover {
  color: #fff;
}

.contact form button::before {
  content: "";
  background: #121212;
  width: 0;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  transition: 0.3s ease-in-out;
}
.contact form button:hover::before {
  width: 100%;
}

.contact-wrapper .contact-icons {
  font-size: 2rem;
  margin-right: 0.5rem;
}

.contact-wrapper h6 {
  margin-bottom: 1rem;
}

.contact-wrapper h6 a {
  text-decoration: none;
  color: #fff;
}

.phone-icon {
  color: rgb(0, 200, 0);
}
.email-icon {
  color: orange;
}
.linkedin-icon {
  color: #0072b1;
}

.gitlab-icon {
  color: #e24329;
}
/*/////////////////////
/-----> /FOOTER/ <-----/ 
/////////////////////*/
.footer {
  padding: 72px 0;
}
.footer p {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  margin-top: 20px;
  margin-bottom: 0;
}

.txt-rotate {
  color: #cdf7ff;
  font-size: 1.8rem;
  background: rgba(153, 51, 153, 0.3);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  transform: perspective(30px);
}

/*/////////////////////
/-----> /MEDIA-QUERIES/ <-----/ 
/////////////////////*/
@media (max-width: 767px) {
  .banner h1 {
    font-size: 2.5rem;
  }
  .banner .tagline {
    font-size: 1.1rem;
  }
  .txt-rotate {
    font-size: 1.3rem;
  }
  .project-content-wrapper img {
    width: 100%;
  }
  .contact-wrapper {
    margin-bottom: 3rem;
  }
  .skill h2,
  .project h2,
  .contact h2 {
    font-size: 2rem;
  }
  .contact-informations {
    padding-left: 0 !important;
  }
}

@media (max-width: 575px) {
  .banner p,
  .skill-bx p,
  .banner .tagline,
  .project p {
    font-size: 1rem;
  }
  .contact-informations {
    padding-left: 4px !important;
  }
}

@media (max-width: 992px) {
  .social-icon {
    margin-left: 0;
  }
}

@media (max-width: 375px) {
  .navbar-text button {
    font-size: 14px;
    margin-left: 5px;
  }
  .button-wrapper {
    flex-wrap: wrap;
  }
  .button-wrapper button {
    margin-top: 1rem;
  }
}
